
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { Modal } from "bootstrap";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import store from "@/store";

export default defineComponent({
  name: "LoanList",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Зээлийн жагсаалт");
    });

    const role = computed(() => {
      return store.getters.getRoleNames;
    });

    return {
      role
    };
  },
  mounted() {
    if (
      this.role.includes("ROLE_FORM_MENU") ||
      this.role.includes("ROLE_ADMIN")
    ) {
      this.getLoan();
      this.getEditLoanModalData();
      this.editLoanForm.loanEditModal = new Modal(
        this.$refs.editLoanModal as HTMLDivElement
      );
    }
  },
  data() {
    const router = useRouter();

    const getLoanDetails = row => {
      const id = this.b64EncodeUnicode(
        row.loancode + "@" + row.orgcode + "@" + row.customercode
      );
      router.push({
        name: "loandetails",
        params: { id: id }
      });
    };

    const getCustDetails = row => {
      const id = this.b64EncodeUnicode(row.loancode + "@" + row.customercode);
      router.push({
        name: "customerdetails",
        params: { id: id }
      });
    };
    return {
      loadButton: "off",
      page: 1,
      pageSize: 10,
      loanSearch: "",
      dataLoading: true,
      editLoanForm: {
        loanEditModal: {} as Modal,
        selectLoanClass: [],
        itemid: "01",
        selectCurrency: [],
        currencycode: "MNT",
        name: "",
        registerno: "",
        advamount: "",
        endDate: "",
        loancode: "",
        customercode: "",
        balance: ""
      },
      tableData: [],
      getLoanDetails,
      getCustDetails
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    },
    handleChange(value) {
      this.pageSize = value;
    },
    indexMethod(index) {
      return (this.page - 1) * this.pageSize + index + 1;
    },
    editLoanHide() {
      this.editLoanForm.loanEditModal.hide();
    },
    editLoanShow(row) {
      const a: any = this.editLoanForm.selectLoanClass.find((e: any) => {
        return row.loanClass === e.name1;
      });
      this.editLoanForm.name = row.customerName;
      this.editLoanForm.loancode = row.loancode;
      this.editLoanForm.customercode = row.customercode;
      this.editLoanForm.registerno = row.registerno;
      this.editLoanForm.advamount = row.loanAmount;
      this.editLoanForm.balance = row.loanBalance;
      this.editLoanForm.currencycode = row.currency;
      this.editLoanForm.itemid = a.itemid;
      this.editLoanForm.endDate = row.endDate;
      this.editLoanForm.loanEditModal.show();
    },
    async getLoan() {
      this.dataLoading = true;
      await ApiService.get("loanlist")
        .then(res => {
          const results = res.data;
          this.tableData = results.map(org => ({
            loanType: org.loantypcode,
            customerName: org.customername,
            registerno: org.registerno,
            loanClass: org.loanclasscode,
            loanAmount: org.advamount,
            loanBalance: org.balance,
            currency: org.currencycode,
            startedDate: org.starteddate,
            endDate: org.update_exp_date,
            interestinprec: org.interestinprec,
            comissionprec: org.comissionprec,
            fee: org.fee,
            loancode: org.loancode1.toString().split("@")[0],
            customercode: org.loancode1.toString().split("@")[1],
            searchfield: org.searchfield
          }));
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    async updateLoanSubmit() {
      this.loadButton = "on";
      const params = {
        loanclass: this.editLoanForm.itemid,
        loancode: this.editLoanForm.loancode,
        customercode: this.editLoanForm.customercode,
        currency: this.editLoanForm.currencycode,
        advamount: this.editLoanForm.advamount,
        expdate: this.editLoanForm.endDate,
        balance: this.editLoanForm.balance
      };
      await ApiService.post("loanlist", { params: params })
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            Swal.fire({
              text: results.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            });
          } else {
            Swal.fire({
              html: results.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "За",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            }).then(() => {
              this.getLoan();
              (document.getElementById(
                "loanEditModalBackButton"
              ) as HTMLButtonElement).click();
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadButton = "off";
        });
    },
    async getEditLoanModalData() {
      await ApiService.get("loanlist", "?methodtype=editloanmodal")
        .then(res => {
          const results = res.data;
          this.editLoanForm.selectCurrency = results.currency;
          this.editLoanForm.selectLoanClass = results.loanclasses;
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        });
    }
  }
});
